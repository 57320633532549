import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const PackageBox = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M386.592 690.656h320a16 16 0 1 1 0 32h-320a16 16 0 1 1 0-32zM306.592 690.656a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM386.592 530.656h320a16 16 0 1 1 0 32h-320a16 16 0 1 1 0-32zM306.592 530.656a16 16 0 1 1 0 32 16 16 0 1 1 0-32zM386.592 370.656h320a16 16 0 1 1 0 32h-320a16 16 0 1 1 0-32zM306.592 370.656a16 16 0 1 1 0 32 16 16 0 0 1 0-32z" />
      <path d="M568.336 242.656h-128c-30.88 0-56-25.12-56-56v-48c0-30.88 25.12-56 56-56h128c30.88 0 56 25.12 56 56v48c0 30.864-25.136 56-56 56z m-128-128c-13.232 0-24 10.768-24 24v48c0 13.232 10.768 24 24 24h128c13.232 0 24-10.768 24-24v-48c0-13.232-10.768-24-24-24h-128z" />
      <path d="M706.48 915.28H306.672c-79.504 0-144.192-62.304-144.192-138.88v-491.52c0-76.576 64.672-138.88 144.192-138.88h31.152a16 16 0 0 1 0 32h-31.152c-61.856 0-112.192 47.952-112.192 106.88v491.52c0 58.944 50.336 106.88 112.192 106.88h399.808c61.856 0 112.192-47.936 112.192-106.88v-491.52c0-58.928-50.336-106.88-112.192-106.88h-31.408a16 16 0 1 1 0-32h31.408c79.504 0 144.192 62.304 144.192 138.88v491.52c0 76.592-64.688 138.88-144.192 138.88z" />
    </SvgIcon>
  );
};

export default PackageBox;
