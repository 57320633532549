import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const RoomSet = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19 19" fill="none">
      <g clipPath="url(#clip0_6800_50526)">
        <path
          d="M4.57422 16.4301H4.334V6.65723C4.334 6.46309 4.17657 6.30566 3.98243 6.30566C3.7883 6.30566 3.63087 6.46309 3.63087 6.65723V16.4301H3.30859C2.33934 16.4301 1.55078 17.2187 1.55078 18.1879C1.55078 18.382 1.70821 18.5395 1.90234 18.5395H5.98047C6.1746 18.5395 6.33203 18.382 6.33203 18.1879C6.33203 17.2187 5.54348 16.4301 4.57422 16.4301ZM2.31416 17.8364C2.45925 17.4271 2.85026 17.1332 3.30859 17.1332H4.57422C5.03255 17.1332 5.42356 17.4271 5.56865 17.8364H2.31416Z"
          fill="currentColor"
        />
        <path
          d="M7.4453 5.09149L5.78125 0.767274C5.729 0.63157 5.59857 0.541992 5.45313 0.541992H2.51171C2.36627 0.541992 2.23584 0.63157 2.1836 0.767274L0.51955 5.09149C0.477925 5.1996 0.492304 5.32128 0.557871 5.41679C0.623402 5.51228 0.731824 5.56934 0.847664 5.56934H1.72657V7.14448C1.72657 7.33861 1.884 7.49604 2.07813 7.49604C2.27227 7.49604 2.4297 7.33861 2.4297 7.14448V5.56934H7.11718C7.23302 5.56934 7.34145 5.51228 7.40698 5.41679C7.47254 5.32128 7.48689 5.1996 7.4453 5.09149ZM1.35968 4.86621L2.7531 1.24512H5.21175L6.6052 4.86621H1.35968Z"
          fill="currentColor"
        />
        <path
          d="M2.32664 8.13133C2.26129 8.0659 2.17059 8.02832 2.07812 8.02832C1.98528 8.02832 1.89496 8.0659 1.82957 8.13133C1.76418 8.19675 1.72656 8.28742 1.72656 8.37988C1.72656 8.47234 1.76414 8.56305 1.82957 8.6284C1.89496 8.69383 1.98566 8.73145 2.07812 8.73145C2.17059 8.73145 2.26129 8.69383 2.32664 8.6284C2.39207 8.56305 2.42969 8.47234 2.42969 8.37988C2.42969 8.28742 2.39207 8.19672 2.32664 8.13133Z"
          fill="currentColor"
        />
        <path
          d="M17.2128 10.6087V10.2159C17.2128 9.30852 16.4746 8.57031 15.5672 8.57031H13.1875C12.9934 8.57031 12.836 8.72774 12.836 8.92187C12.836 9.11601 12.9934 9.27344 13.1875 9.27344H15.5672C16.0869 9.27344 16.5097 9.69619 16.5097 10.2159V10.6087C15.8117 10.7623 15.2799 11.3629 15.2305 12.0941C15.1282 12.0597 15.0189 12.0408 14.9052 12.0408H8.65741C8.54371 12.0408 8.43437 12.0597 8.33211 12.0941C8.28278 11.3629 7.75087 10.7623 7.05291 10.6087V10.2159C7.05291 9.69623 7.4757 9.27344 7.99541 9.27344H10.3047C10.4989 9.27344 10.6563 9.11601 10.6563 8.92187C10.6563 8.72774 10.4989 8.57031 10.3047 8.57031H7.99538C7.08796 8.57031 6.34975 9.30852 6.34975 10.2159V10.6087C5.61671 10.77 5.06641 11.4243 5.06641 12.2051V15.9375C5.06641 16.1316 5.22384 16.2891 5.41797 16.2891H18.1445C18.3387 16.2891 18.4961 16.1316 18.4961 15.9375V12.2052C18.4961 11.4243 17.9458 10.77 17.2128 10.6087ZM7.63316 13.0651V13.7986V15.586H5.76957V12.2052C5.76957 11.6914 6.18757 11.2734 6.70135 11.2734C7.21516 11.2734 7.63316 11.6914 7.63316 12.2052V13.0651ZM15.2263 15.586H8.33629V14.1502H15.2263V15.586ZM15.2263 13.4471H8.33629V13.0651C8.33629 12.888 8.48036 12.744 8.65741 12.744H14.9052C15.0822 12.744 15.2263 12.888 15.2263 13.0651V13.4471V13.4471ZM17.793 15.586H15.9294V13.7986V13.0651V12.2052C15.9294 11.6914 16.3474 11.2734 16.8612 11.2734C17.375 11.2734 17.793 11.6914 17.793 12.2052V15.586Z"
          fill="currentColor"
        />
        <path
          d="M12.0298 8.67332C11.9644 8.60758 11.8737 8.57031 11.7812 8.57031C11.6888 8.57031 11.5981 8.60758 11.5327 8.67332C11.4673 8.73871 11.4297 8.8291 11.4297 8.92188C11.4297 9.01434 11.4673 9.10504 11.5327 9.17043C11.5981 9.23579 11.6888 9.27344 11.7812 9.27344C11.8737 9.27344 11.9644 9.23582 12.0298 9.17043C12.0952 9.10504 12.1328 9.01434 12.1328 8.92188C12.1328 8.82906 12.0952 8.73871 12.0298 8.67332Z"
          fill="currentColor"
        />
        <path
          d="M17.4414 0.541016H10.1992C9.61766 0.541016 9.14453 1.01415 9.14453 1.5957V5.32226C9.14453 5.90381 9.61766 6.37695 10.1992 6.37695H17.4414C18.023 6.37695 18.4961 5.90381 18.4961 5.32226V1.5957C18.4961 1.01415 18.023 0.541016 17.4414 0.541016ZM17.793 5.32226C17.793 5.51608 17.6353 5.67382 17.4414 5.67382H15.1206C15.1108 5.66078 15.1003 5.64805 15.0884 5.63617L12.0386 2.58637C11.9013 2.44912 11.6788 2.44912 11.5414 2.58637L10.5222 3.60562C10.3849 3.7429 10.3849 3.96551 10.5222 4.10283C10.6595 4.24008 10.882 4.24008 11.0194 4.10283L11.79 3.33217L14.1317 5.67382H10.1992C10.0054 5.67382 9.84766 5.51611 9.84766 5.32226V1.5957C9.84766 1.40185 10.0054 1.24414 10.1992 1.24414H17.4414C17.6353 1.24414 17.793 1.40185 17.793 1.5957V3.4188L16.3716 1.99747C16.2343 1.86022 16.0118 1.86022 15.8744 1.99747L14.5783 3.29361C14.441 3.43089 14.441 3.6535 14.5783 3.79082C14.7156 3.92807 14.9381 3.92807 15.0755 3.79082L16.123 2.74327L17.7202 4.34038C17.7424 4.3626 17.767 4.38085 17.793 4.39586V5.32226Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6800_50526">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.496094 0.540039)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default RoomSet;
