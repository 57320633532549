import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const FeedbackThumbsUp = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 50 50">
      <path d="M44.6289 7.8125H43.1641V4.39453C43.1641 1.97139 41.1927 0 38.7695 0C36.3464 0 34.375 1.97139 34.375 4.39453V5.59424C34.375 7.11221 33.3557 8.46367 31.896 8.88066L30.4688 9.28848V9.27734C30.4688 8.46836 29.8129 7.8125 29.0039 7.8125H23.1445C22.3355 7.8125 21.6797 8.46836 21.6797 9.27734V15.8203H4.39453C1.97139 15.8203 0 17.7917 0 20.2148V48.5352C0 49.0754 0.297363 49.5718 0.773633 49.8267C0.990234 49.9427 1.22773 50 1.46475 50C1.74893 50 2.03232 49.9174 2.27734 49.7539L7.76777 46.0938H35.6445C38.0677 46.0938 40.0391 44.1224 40.0391 41.6992V26.3672H44.6289C47.5905 26.3672 50 23.9577 50 20.9961V13.1836C50 10.222 47.5905 7.8125 44.6289 7.8125ZM24.6094 10.7422H27.5391V23.4375H24.6094V10.7422ZM37.1094 41.6992C37.1094 42.5069 36.4522 43.1641 35.6445 43.1641H7.32422C7.03506 43.1641 6.75234 43.2497 6.51172 43.4102L2.92969 45.7981V20.2148C2.92969 19.4071 3.58682 18.75 4.39453 18.75H21.6797V24.9023C21.6797 25.7113 22.3355 26.3672 23.1445 26.3672H29.0039C29.7564 26.3672 30.3758 25.7994 30.4586 25.069C31.7636 25.9013 33.298 26.3672 34.8937 26.3672H37.1094V41.6992ZM47.0703 20.9961C47.0703 22.3423 45.9751 23.4375 44.6289 23.4375H34.8937C33.1126 23.4375 31.4492 22.539 30.4688 21.0697V12.3354L32.7008 11.6978C35.4115 10.9232 37.3047 8.41338 37.3047 5.59424V4.39453C37.3047 3.58682 37.9618 2.92969 38.7695 2.92969C39.5772 2.92969 40.2344 3.58682 40.2344 4.39453V9.27734C40.2344 10.0863 40.8902 10.7422 41.6992 10.7422H44.6289C45.9751 10.7422 47.0703 11.8374 47.0703 13.1836V20.9961Z" />
      <path d="M7.32422 27.5391H17.0898C17.8988 27.5391 18.5547 26.8832 18.5547 26.0742C18.5547 25.2652 17.8988 24.6094 17.0898 24.6094H7.32422C6.51523 24.6094 5.85938 25.2652 5.85938 26.0742C5.85938 26.8832 6.51523 27.5391 7.32422 27.5391Z" />
      <path d="M32.7148 30.4688H7.32422C6.51523 30.4688 5.85938 31.1246 5.85938 31.9336C5.85938 32.7426 6.51523 33.3984 7.32422 33.3984H32.7148C33.5238 33.3984 34.1797 32.7426 34.1797 31.9336C34.1797 31.1246 33.5238 30.4688 32.7148 30.4688Z" />
      <path d="M32.7148 36.3281H7.32422C6.51523 36.3281 5.85938 36.984 5.85938 37.793C5.85938 38.602 6.51523 39.2578 7.32422 39.2578H32.7148C33.5238 39.2578 34.1797 38.602 34.1797 37.793C34.1797 36.984 33.5238 36.3281 32.7148 36.3281Z" />
    </SvgIcon>
  );
};

export default FeedbackThumbsUp;
