import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const OnlineService = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 22 20">
      <path d="M22.2578 11.5C22.2578 9.76082 21.0117 8.30403 19.3622 7.9813C19.0305 3.93367 15.6328 0.742188 11.5 0.742188C7.36721 0.742188 3.96953 3.93367 3.63783 7.9813C1.9883 8.30403 0.742188 9.76082 0.742188 11.5C0.742188 13.4767 2.35138 15.0859 4.32813 15.0859H5.04531C6.23315 15.0859 7.19688 14.1222 7.19688 12.9344V10.0656C7.19688 8.89123 6.25108 7.93647 5.08565 7.91406C5.44425 4.6912 8.18301 2.17656 11.5 2.17656C14.817 2.17656 17.5558 4.6912 17.9143 7.91406C16.7444 7.93647 15.8031 8.89123 15.8031 10.0656V12.9344C15.8031 14.0953 16.731 15.0456 17.883 15.0859C17.5513 16.722 16.1034 17.9547 14.3688 17.9547H13.5261C13.2302 17.121 12.4323 16.5203 11.5 16.5203C10.3122 16.5203 9.34844 17.484 9.34844 18.6719C9.34844 19.8597 10.3122 20.8234 11.5 20.8234C12.4368 20.8234 13.2302 20.2228 13.5261 19.3891H14.3688C16.9148 19.3891 19.026 17.484 19.3487 15.0232C21.0027 14.7049 22.2578 13.2481 22.2578 11.5V11.5ZM2.17656 11.5C2.17656 10.5632 2.77721 9.76979 3.61094 9.47395V13.5305C2.77721 13.2302 2.17656 12.4368 2.17656 11.5ZM5.7625 10.0656V12.9344C5.7625 13.3288 5.43977 13.6516 5.04531 13.6516V9.34844C5.43977 9.34844 5.7625 9.67117 5.7625 10.0656ZM11.5 19.3891C11.1055 19.3891 10.7828 19.0663 10.7828 18.6719C10.7828 18.2774 11.1055 17.9547 11.5 17.9547C11.8945 17.9547 12.2172 18.2774 12.2172 18.6719C12.2172 19.0663 11.8945 19.3891 11.5 19.3891ZM17.2375 12.9344V10.0656C17.2375 9.67117 17.5602 9.34844 17.9547 9.34844V13.6516C17.5602 13.6516 17.2375 13.3288 17.2375 12.9344ZM19.3891 13.5261V9.46946C20.2228 9.7653 20.8234 10.5632 20.8234 11.4955C20.8234 12.4279 20.2228 13.2302 19.3891 13.5261Z" />
    </SvgIcon>
  );
};

export default OnlineService;
