import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Happiness = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 480 480">
     <g >
<path d="M223 622 c-109 -39 -178 -112 -210 -221 -29 -102 4 -228 82 -306 122
-121 328 -121 450 0 91 92 118 241 64 356 -69 146 -241 223 -386 171z m199
-42 c185 -70 237 -314 96 -457 -139 -142 -389 -90 -458 95 -86 229 134 449
362 362z"/>
<path d="M163 448 c-36 -18 -55 -88 -23 -88 5 0 15 12 22 28 9 19 21 27 38 27
17 0 29 -8 38 -27 7 -16 17 -28 22 -28 34 0 13 71 -26 89 -30 13 -42 13 -71
-1z"/>
<path d="M403 448 c-36 -18 -55 -88 -23 -88 5 0 15 12 22 28 9 19 21 27 38 27
17 0 29 -8 38 -27 7 -16 17 -28 22 -28 34 0 13 71 -26 89 -30 13 -42 13 -71
-1z"/>
<path d="M135 270 c-18 -29 70 -122 132 -139 47 -13 59 -13 106 0 69 19 155
116 127 143 -9 8 -15 6 -24 -9 -6 -11 -27 -36 -46 -57 -76 -78 -204 -56 -256
43 -15 30 -28 36 -39 19z"/>
</g>
    </SvgIcon>
  );
};

export default Happiness;
