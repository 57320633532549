import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Products = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19 19" fill="none">
      <g clipPath="url(#clip0_6800_50540)">
        <path
          d="M3.38281 1.97949H1.13281V4.22949H3.38281V1.97949Z"
          stroke="#7D879C"
          strokeMiterlimit="10"
        />
        <path
          d="M3.38281 6.47949H1.13281V8.72949H3.38281V6.47949Z"
          stroke="#7D879C"
          strokeMiterlimit="10"
        />
        <path
          d="M3.38281 10.9795H1.13281V13.2295H3.38281V10.9795Z"
          stroke="#7D879C"
          strokeMiterlimit="10"
        />
        <path
          d="M3.38281 15.4795H1.13281V17.7295H3.38281V15.4795Z"
          stroke="#7D879C"
          strokeMiterlimit="10"
        />
        <path
          d="M6.19531 3.10449H18.5703"
          stroke="#7D879C"
          strokeMiterlimit="10"
        />
        <path
          d="M6.19531 7.60449H16.3203"
          stroke="#7D879C"
          strokeMiterlimit="10"
        />
        <path
          d="M6.19531 12.1045H14.0703"
          stroke="#7D879C"
          strokeMiterlimit="10"
        />
        <path
          d="M6.19531 16.6045H16.3203"
          stroke="#7D879C"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_6800_50540">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.851562 0.854492)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Products;
