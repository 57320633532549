import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Stool = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 19" fill="none">
      <path
        d="M14.4727 0.0400391H2.51953C1.45335 0.0400391 0.585938 0.907449 0.585938 1.97363V4.46973C0.585938 5.53591 1.45335 6.40332 2.51953 6.40332H3.14612L1.80667 16.2558C1.67838 17.1997 2.41311 18.04 3.36472 18.04C4.14755 18.04 4.81738 17.4551 4.92281 16.6794L5.60295 11.6768H11.3873L12.0692 16.68C12.1748 17.4554 12.8446 18.04 13.6271 18.04C14.5798 18.04 15.3136 17.1985 15.1851 16.2554L13.8425 6.40332H14.4727C15.5388 6.40332 16.4062 5.53591 16.4062 4.46973V1.97363C16.4062 0.907449 15.5388 0.0400391 14.4727 0.0400391V0.0400391ZM3.87776 16.5374C3.84306 16.7928 3.62249 16.9854 3.36472 16.9854C3.05078 16.9854 2.80936 16.7096 2.85172 16.3979L4.21051 6.40332H5.2555L3.87776 16.5374ZM10.6687 6.40332L10.9562 8.5127H6.03312L6.31989 6.40332H10.6687ZM5.74635 10.6221L5.88972 9.56738H11.0999L11.2436 10.6221H5.74635ZM14.1401 16.3978C14.1824 16.7089 13.9416 16.9854 13.6271 16.9854C13.3695 16.9854 13.149 16.7928 13.1141 16.5376L11.7331 6.40332H12.7781L14.1401 16.3978ZM15.3516 4.46973C15.3516 4.95436 14.9573 5.34863 14.4727 5.34863H2.51953C2.0349 5.34863 1.64062 4.95436 1.64062 4.46973V1.97363C1.64062 1.489 2.0349 1.09473 2.51953 1.09473H14.4727C14.9573 1.09473 15.3516 1.489 15.3516 1.97363V4.46973Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Stool;
