import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Payment = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 42 43">
      <g>
        <path
          d="M41.3952 15.3162L36.738 3.20735C35.8555 0.9183 33.2844 -0.221888 30.9953 0.660625C30.9621 0.673466 30.929 0.686653 30.896 0.700274L7.62232 10.3479C5.40623 11.257 4.31705 13.7654 5.16599 16.0053L5.80593 17.7134H4.33796C1.94332 17.7161 0.00268954 19.6567 0 22.0513V37.668C0.00268954 40.0626 1.94332 42.0033 4.33796 42.006H33.8361C36.2307 42.0033 38.1714 40.0626 38.174 37.668V22.0513C38.1727 21.8159 38.1517 21.5809 38.1116 21.3489L38.9174 21.0227C41.1533 20.1063 42.2522 17.5755 41.3952 15.3162ZM4.33796 19.1016H33.8361C35.3939 19.1061 36.679 20.3227 36.7685 21.8779H1.4055C1.49503 20.3226 2.78011 19.106 4.33796 19.1016ZM11.3141 14.9205L15.9533 12.6002L16.9069 14.9858L12.2677 17.3054L11.3141 14.9205ZM36.7859 37.6681C36.784 39.2965 35.4645 40.616 33.8361 40.6179H4.33796C2.70958 40.616 1.39006 39.2965 1.38815 37.6681V28.8186H36.7859V37.6681ZM36.7859 27.4305H1.38815V23.2661H36.7859V27.4305ZM38.3968 19.7402L37.6715 20.0338C36.9224 18.6088 35.446 17.7157 33.8361 17.7135H14.5561L18.0994 15.9415C18.4224 15.7802 18.5679 15.3981 18.4339 15.0628L16.9618 11.3842C16.8194 11.0283 16.4155 10.8552 16.0596 10.9975C16.0417 11.0047 16.0241 11.0126 16.0068 11.0212L10.1218 13.9655C9.79893 14.127 9.65378 14.509 9.78791 14.8442L10.9359 17.7135H7.28777L6.46599 15.5216C5.8834 13.9827 6.6317 12.2596 8.15398 11.6348L31.427 1.98717C32.9828 1.34203 34.7671 2.08027 35.4123 3.63612C35.4224 3.66032 35.4321 3.6847 35.4415 3.70917L40.0987 15.818C40.6879 17.3705 39.9332 19.1099 38.3968 19.7402Z"
          fill="#2B3445"
        />
        <path
          d="M24.967 38.666C26.2604 39.4174 27.8576 39.4174 29.151 38.666C31.1397 39.8214 33.6885 39.1458 34.8439 37.1571C35.9992 35.1684 35.3236 32.6196 33.3349 31.4642C32.6967 31.0934 31.9714 30.8989 31.2332 30.9006C30.5015 30.9009 29.7829 31.0954 29.151 31.4642C27.1623 30.3088 24.6135 30.9843 23.4581 32.973C22.3027 34.9618 22.9783 37.5105 24.967 38.666ZM30.3115 32.4582C30.6067 32.3494 30.9185 32.2921 31.2332 32.2888C32.7665 32.2888 34.0095 33.5318 34.0095 35.0651C34.0095 36.5984 32.7665 37.8414 31.2332 37.8414C30.9185 37.8381 30.6067 37.7809 30.3115 37.6721C31.5403 36.1513 31.5403 33.9789 30.3115 32.4582ZM29.151 33.248C30.0765 34.2827 30.0765 35.8475 29.151 36.8822C28.2255 35.8475 28.2255 34.2826 29.151 33.248ZM27.0688 32.2888C27.3834 32.292 27.6953 32.3494 27.9905 32.4582C26.7616 33.9789 26.7616 36.1514 27.9905 37.6721C27.6953 37.7809 27.3834 37.8381 27.0688 37.8414C25.5355 37.8414 24.2925 36.5984 24.2925 35.0651C24.2925 33.5317 25.5355 32.2888 27.0688 32.2888Z"
          fill="#2B3445"
        />
        <path
          d="M29.2776 14.6859L29.8415 15.9543L36.463 13.011L35.8991 11.7425L29.2776 14.6859Z"
          fill="#2B3445"
        />
        <path
          d="M34.4214 8.80402L24.8572 13.2185L25.4387 14.4783L35.0028 10.0638L34.4214 8.80402Z"
          fill="#2B3445"
        />
      </g>
    </SvgIcon>
  );
};

export default Payment;
