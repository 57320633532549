import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Dining = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19 19" fill="none">
      <path
        d="M17.6592 4.62553C17.485 4.60379 17.3263 4.72736 17.3045 4.90134L16.6332 10.2716H14.1559L13.8286 8.41578C13.8019 8.26398 13.67 8.15322 13.5157 8.15322H5.50186C5.34796 8.15322 5.21612 8.26354 5.1891 8.41512L4.85757 10.2714H2.36681L1.6955 4.90134C1.67376 4.72725 1.5149 4.60379 1.34081 4.62553C1.16672 4.64726 1.04316 4.80613 1.065 4.98022L1.77107 10.6288C1.79093 10.7877 1.92607 10.9071 2.08626 10.9071H2.65266L1.98466 14.9147C1.95586 15.0878 2.0728 15.2514 2.24579 15.2804C2.419 15.3092 2.58272 15.1923 2.61151 15.0192L3.29695 10.9071H4.74438L3.77696 16.3232C3.75534 16.4438 3.80509 16.5661 3.90449 16.6374L3.912 16.6428C4.84423 17.3109 6.08723 17.3516 7.06117 16.7457C8.33727 17.3475 9.81637 17.3447 11.0903 16.7378C11.6596 17.0353 12.2907 17.1961 12.9331 17.2072C12.9557 17.2076 12.9782 17.2078 13.0008 17.2078C13.6791 17.2073 14.3469 17.0401 14.9453 16.7206L15.0598 16.6593C15.1809 16.5945 15.2467 16.4592 15.2229 16.324L14.2679 10.9071H15.7032L16.3885 15.0192C16.4061 15.1321 16.483 15.227 16.5899 15.2676C16.6969 15.308 16.8172 15.288 16.9053 15.2149C16.9932 15.142 17.0354 15.0273 17.0153 14.9147L16.3473 10.9071H16.9137C17.074 10.9071 17.2091 10.7877 17.229 10.6288L17.935 4.98022C17.9568 4.80613 17.8332 4.64737 17.6592 4.62553V4.62553ZM11.4152 16.19V12.1074C11.4152 11.932 11.273 11.7897 11.0974 11.7897C10.922 11.7897 10.7797 11.932 10.7797 12.1074V16.1811C9.69391 16.685 8.4412 16.685 7.35529 16.1811V13.343C7.35529 13.1676 7.21308 13.0253 7.03756 13.0253C6.86214 13.0253 6.71983 13.1676 6.71983 13.343V16.2093C6.02259 16.6403 5.14375 16.6478 4.43934 16.2287L5.76785 8.7889H13.2492L14.5572 16.2061C13.5676 16.7037 12.3997 16.6977 11.4152 16.19V16.19Z"
        fill="currentColor"
      />
      <path
        d="M7.20445 7.74409H11.4408C11.6164 7.74409 11.7586 7.60188 11.7586 7.42636C11.7569 6.20465 10.8516 5.1727 9.64038 5.01196V4.71519C9.64038 4.53967 9.49817 4.39746 9.32265 4.39746C9.14723 4.39746 9.00492 4.53967 9.00492 4.71519V5.01196C7.7939 5.1727 6.88837 6.20465 6.88672 7.42636C6.88672 7.60188 7.02904 7.74409 7.20445 7.74409ZM9.32265 5.6259C10.1941 5.62722 10.94 6.25121 11.0949 7.10863H7.55042C7.70542 6.25121 8.45121 5.62722 9.32265 5.6259Z"
        fill="currentColor"
      />
      <path
        d="M10.3802 4.30583C10.5558 4.30583 10.698 4.16352 10.698 3.9881V3.17613C10.698 3.00071 10.5558 2.8584 10.3802 2.8584C10.2048 2.8584 10.0625 3.00071 10.0625 3.17613V3.9881C10.0625 4.16352 10.2048 4.30583 10.3802 4.30583Z"
        fill="currentColor"
      />
      <path
        d="M8.93491 3.70521C9.11043 3.70521 9.25264 3.5629 9.25264 3.38748V2.11656C9.25264 1.94115 9.11043 1.79883 8.93491 1.79883C8.7595 1.79883 8.61719 1.94115 8.61719 2.11656V3.38748C8.61719 3.5629 8.7595 3.70521 8.93491 3.70521Z"
        fill="currentColor"
      />
      <path
        d="M7.59117 4.55329C7.76669 4.55329 7.9089 4.41098 7.9089 4.23556V3.5648C7.9089 3.38939 7.76669 3.24707 7.59117 3.24707C7.41576 3.24707 7.27344 3.38939 7.27344 3.5648V4.23556C7.27344 4.41098 7.41576 4.55329 7.59117 4.55329Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Dining;
