import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Bottle = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M14.1653 11.1972L13.3094 7.12293C13.2762 6.96457 13.1208 6.86309 12.9625 6.89645C12.8041 6.92973 12.7028 7.08504 12.736 7.2434L13.5919 11.3178C13.617 11.437 13.6335 11.5582 13.6432 11.68H6.35689C6.36657 11.5582 6.3831 11.437 6.40825 11.3177L8.26486 2.48008H11.7353L12.454 5.90164C12.4872 6.05996 12.6424 6.16137 12.8009 6.12813C12.9593 6.09484 13.0606 5.93953 13.0274 5.78117L12.3306 2.46371C12.6318 2.39684 12.8579 2.12781 12.8579 1.80664V0.673047C12.8579 0.301914 12.556 0 12.1848 0H9.67506C9.51326 0 9.38209 0.131133 9.38209 0.292969C9.38209 0.454805 9.51326 0.585938 9.67506 0.585938H12.1848C12.2328 0.585938 12.2719 0.625039 12.2719 0.673047V1.80664C12.2719 1.85488 12.2328 1.89414 12.1848 1.89414H7.81529C7.76728 1.89414 7.72818 1.85488 7.72818 1.80664V0.673047C7.72818 0.625039 7.76724 0.585938 7.81529 0.585938H8.30396C8.46576 0.585938 8.59693 0.454805 8.59693 0.292969C8.59693 0.131133 8.46576 0 8.30396 0H7.81529C7.44419 0 7.14224 0.301914 7.14224 0.673047V1.80664C7.14224 2.12785 7.36833 2.39687 7.66954 2.46375L5.83485 11.1971C5.78583 11.4298 5.76099 11.6684 5.76099 11.9062V13.6617C5.76099 13.8236 5.89216 13.9547 6.05396 13.9547C6.21575 13.9547 6.34692 13.8236 6.34692 13.6617V12.2659H13.6532V16.9676H6.34692V15.0328C6.34692 14.871 6.21575 14.7398 6.05396 14.7398C5.89216 14.7398 5.76099 14.871 5.76099 15.0328V18.1285C5.76099 19.1605 6.60052 20 7.63247 20H12.3676C13.3996 20 14.2391 19.1605 14.2391 18.1285V11.9062C14.2391 11.6682 14.2143 11.4296 14.1653 11.1972ZM12.3677 19.4141H7.63247C6.92364 19.4141 6.34692 18.8373 6.34692 18.1285V17.5535H13.6532V18.1285C13.6532 18.8373 13.0765 19.4141 12.3677 19.4141Z" />
      <path d="M11.6346 14.617C11.6346 13.7157 10.9014 12.9824 10 12.9824C9.0987 12.9824 8.36542 13.7157 8.36542 14.617C8.36542 15.5183 9.0987 16.2516 10 16.2516C10.9013 16.2516 11.6346 15.5184 11.6346 14.617ZM8.95139 14.617C8.95139 14.0388 9.42182 13.5684 10.0001 13.5684C10.5783 13.5684 11.0487 14.0388 11.0487 14.617C11.0487 15.1952 10.5783 15.6657 10.0001 15.6657C9.42182 15.6657 8.95139 15.1952 8.95139 14.617Z" />
    </SvgIcon>
  );
};

export default Bottle;
