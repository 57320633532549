import FeedbackThumbsUp from "./FeedbackThumbsUp";
import Shield from "./Shield";
import Truck from "./Truck";
import Apple from "./Apple";
import AppleStore from "./AppleStore";
import PlayStore from "./PlayStore";
import Beer from "./Beer";
import Bottle from "./Bottle";
import Breakfast from "./Breakfast";
import Carrot from "./Carrot";
import Honey from "./Honey";
import Milk from "./Milk";
import Snack from "./Snack";
import Yogurt from "./Yogurt";
import Order from "./Order";
import CreditCardVerified from "./CreditCardVerified";
import CustomerService from "./CustomerService";
import AlarmClock from "./AlarmClock";
import MoneyGuarantee from "./MoneyGurantee";
import OnlineSupport from "./OnlineSupport";
import Payment from "./Payment";
import Category from "./Category";
import PickUpBox from "./PickUpBox";
import OnlineService from "./OnlineService";
// furniture shop page icons
import Home from "./Home";
import Popular from "./furniture-shop/Popular";
import Trending from "./furniture-shop/Trending";
import Products from "./furniture-shop/Products";
import Chair from "./furniture-shop/Chair";
import Decoration from "./furniture-shop/Decoration";
import Interior from "./furniture-shop/Interior";
import Furniture from "./furniture-shop/Furniture";
import Stool from "./furniture-shop/Stool";
import Wardrobe from "./furniture-shop/Wardrobe";
import Sofa from "./furniture-shop/Sofa";
import Dining from "./furniture-shop/Dining";
import Living from "./furniture-shop/Living";
import RoundTable from "./furniture-shop/RoundTable";
import RoomSet from "./furniture-shop/RoomSet";
// gift shop page icon
import BirthdayCake from "./gift-shop/BirthdayCake";
import Children from "./gift-shop/Children";
import Chirstmas from "./gift-shop/Christmas";
import Flower from "./gift-shop/Flower";
import TeddyBear from "./gift-shop/TeddyBear";
import ValentineGift from "./gift-shop/ValentineGift";
import WeddingGift from "./gift-shop/WeddingGift";
import NewYearGift from "./gift-shop/NewYearGift";
import Toys from "./gift-shop/Toys";
import Football from "./gift-shop/Football";
import BabyToys from "./gift-shop/BabyToys";
import Robot from "./gift-shop/Robot";
// health and beauty homepage icons
import HealthBeauty from "./health-and-beauty/HealthBeauty";
import Mascara from "./health-and-beauty/Mascara";
import Powder from "./health-and-beauty/Powder";
import Lotion from "./health-and-beauty/Lotion";
import Lipstick from "./health-and-beauty/Lipstick";
import FaceMask from "./health-and-beauty/FaceMask";
import Thermometer from "./health-and-beauty/Thermometer";
import OxygenPump from "./health-and-beauty/OxygenPump";
import LenseCase from "./health-and-beauty/LenseCase";
import AutoMotive from "./health-and-beauty/Automotive";
// social icons
import Facebook from "./Facebook";
import Instagram from "./Instagram";
import Youtube from "./Youtube";
import Twitter from "./Twitter";
import Google from "./Google";

import BabyBoy from "./BabyBoy";
import BabyGirl from "./BabyGirl";
import Car from "./Car";
import Dress from "./Dress";
import Food from "./Food";
import Gift from "./Gift";
import Laptop from "./Laptop";
import MakeUp from "./MakeUp";
import Man from "./Man";
import Microphone from "./Microphone";
import MotorBike from "./MotorBike";
import Pets from "./Pets";
import PlantPot from "./PlantPot";
import Woman from "./Woman";
import NominLogo from "./NominLogo";
import delivery from "./Delivery";
import CarIcon from "./CarIcon";
import CartNew from "./cartnew";
import BonusT from "./BonusT";
import Eye from "./Eye";
import Happiness from "./happiness";
import CarThick from "./CartThick";
import ChDown from "./ChDown";
import Close from "./Close";
import ChLeft from "./ChLeft";
import Iconz from "./Iconz";

const appIcons = {
  Beer,
  Milk,
  Snack,
  Truck,
  Honey,
  Apple,
  Shield,
  Bottle,
  Carrot,
  Yogurt,
  Payment,
  PickUpBox,
  Breakfast,
  AlarmClock,
  OnlineSupport,
  OnlineService,
  MoneyGuarantee,
  CustomerService,
  FeedbackThumbsUp,
  CreditCardVerified,
  CartNew,

  // furniture-shop-icons
  Home,
  Sofa,
  Chair,
  Stool,
  Dining,
  Living,
  Popular,
  RoomSet,
  Trending,
  Products,
  Interior,
  Wardrobe,
  Furniture,
  Decoration,
  RoundTable,

  // gift-shop-page-icons
  Toys,
  Robot,
  Flower,
  BabyToys,
  Children,
  Football,
  Chirstmas,
  TeddyBear,
  WeddingGift,
  NewYearGift,
  BirthdayCake,
  ValentineGift,

  // health-and-beauty-page-icons
  Powder,
  Lotion,
  Mascara,
  Lipstick,
  FaceMask,
  LenseCase,
  OxygenPump,
  AutoMotive,
  Thermometer,
  HealthBeauty,

  AppleStore,
  PlayStore,

  // social icons
  Facebook,
  Instagram,
  Youtube,
  Twitter,
  Google,

  // categories
  PlantPot,
  Pets,
  MotorBike,
  Microphone,
  Man,
  MakeUp,
  Laptop,
  Gift,
  Food,
  Dress,
  Car,
  BabyGirl,
  BabyBoy,
  Woman,
  Category,
  delivery,
  NominLogo,
  CarIcon,
  BonusT,
  Eye,
  Happiness,
  CarThick,
  Order,
  ChDown,
  Close,
  ChLeft,
  Iconz
};

export default appIcons;
