import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const CartNew = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 14">
<svg id="Ñëîé_1" data-name="Ñëîé 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
<path d="M404.67,409.53a39.74,39.74,0,1,0,39.73,39.74A39.79,39.79,0,0,0,404.67,409.53Zm0,59.47a19.74,19.74,0,1,1,19.73-19.73A19.76,19.76,0,0,1,404.67,469Z"/>
<path d="M196.53,409.53a39.74,39.74,0,1,0,39.74,39.74A39.78,39.78,0,0,0,196.53,409.53Zm0,59.47a19.74,19.74,0,1,1,19.74-19.73A19.75,19.75,0,0,1,196.53,469Z"/>
<path d="M486.68,115.79A10,10,0,0,0,479,112.2H131.9l-14.73-81A10,10,0,0,0,107.33,23H33a10,10,0,0,0,0,20H99l14.69,80.79c0,.2.06.4.1.59L143.41,287.3a6.17,6.17,0,0,0,.11.62l9,49.52a39.72,39.72,0,0,0,39.09,32.63H434.4a10,10,0,0,0,0-20H191.61a19.73,19.73,0,0,1-19.41-16.21l-6.93-38.13H424.44a39.65,39.65,0,0,0,39.13-32.59L488.84,124A10,10,0,0,0,486.68,115.79Zm-42.8,143.8a19.66,19.66,0,0,1-19.44,16.14H161.63L135.53,132.2H467Z"/>
</svg>
    </SvgIcon>
  );
};

export default CartNew;
