import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ChLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="15 18 9 12 15 6"></polyline>
      </svg>
    </SvgIcon>
  );
};

export default ChLeft;
