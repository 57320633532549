import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Shield = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 64 64">
      <g>
        <path d="M59.186 17.3648L59.184 17.315C59.1562 16.7003 59.1376 16.0499 59.1264 15.3263C59.0741 11.7975 56.269 8.87852 52.7397 8.68125C45.3813 8.27061 39.6889 5.8712 34.8252 1.13049L34.7837 1.09094C33.1973 -0.363646 30.8042 -0.363646 29.2173 1.09094L29.1758 1.13049C24.312 5.8712 18.6197 8.27061 11.2613 8.68174C7.73248 8.87852 4.92683 11.7975 4.87458 15.3267C4.86384 16.0455 4.8448 16.6959 4.81696 17.315L4.81403 17.4307C4.67097 24.9341 4.49323 34.2725 7.61725 42.7485C9.33502 47.4097 11.9366 51.4614 15.3492 54.792C19.2359 58.5849 24.3267 61.5961 30.48 63.7416C30.6802 63.8115 30.8882 63.8681 31.1001 63.9106C31.3989 63.9702 31.6997 63.9999 32.0005 63.9999C32.3013 63.9999 32.6025 63.9702 32.9009 63.9106C33.1128 63.8681 33.3223 63.811 33.5234 63.7407C39.6694 61.5913 44.7548 58.5786 48.6381 54.7861C52.0493 51.4546 54.6508 47.4018 56.37 42.7397C59.5058 34.2383 59.3285 24.8824 59.186 17.3648ZM52.8466 41.4404C49.5517 50.374 42.8256 56.5092 32.2842 60.1958C32.2466 60.2085 32.2065 60.2197 32.165 60.228C32.0561 60.2495 31.9453 60.2495 31.835 60.2275C31.7939 60.2192 31.7539 60.2085 31.7168 60.1958C21.1641 56.5161 14.4336 50.3843 11.1412 41.4502C8.26178 33.6372 8.42486 25.065 8.56891 17.5025L8.56988 17.4605C8.59918 16.8106 8.61871 16.13 8.62945 15.3819C8.65289 13.815 9.90093 12.5186 11.4708 12.4312C15.6348 12.1988 19.293 11.3941 22.6543 9.97129C26.0112 8.55039 28.9927 6.54747 31.7681 3.84826C31.9092 3.72912 32.0923 3.72863 32.2329 3.84826C35.0088 6.54747 37.9902 8.55039 41.3466 9.97129C44.708 11.3941 48.3662 12.1988 52.5307 12.4312C54.1005 12.5186 55.3486 13.815 55.3715 15.3824C55.3827 16.1344 55.4023 16.815 55.4316 17.4605C55.5751 25.0318 55.7343 33.6109 52.8466 41.4404Z" />
        <path d="M31.9996 16.0518C23.2047 16.0518 16.0499 23.207 16.0499 32.0015C16.0499 40.7964 23.2047 47.9517 31.9996 47.9517C40.794 47.9517 47.9493 40.7964 47.9493 32.0015C47.9493 23.207 40.794 16.0518 31.9996 16.0518ZM31.9996 44.1958C25.2755 44.1958 19.8052 38.7256 19.8052 32.0015C19.8052 25.2773 25.2755 19.8071 31.9996 19.8071C38.7232 19.8071 44.1934 25.2773 44.1934 32.0015C44.1934 38.7256 38.7232 44.1958 31.9996 44.1958Z" />
        <path d="M36.5836 27.0494L29.1754 34.4576L27.1646 32.4469C26.4312 31.7135 25.2423 31.7135 24.5089 32.4469C23.776 33.1803 23.776 34.3693 24.5089 35.1022L27.8477 38.441C28.2144 38.8077 28.6949 38.9908 29.1754 38.9908C29.6558 38.9908 30.1363 38.8077 30.503 38.441L39.2393 29.7047C39.9722 28.9713 39.9722 27.7823 39.2393 27.0494C38.5059 26.3156 37.317 26.3156 36.5836 27.0494Z" />
      </g>
    </SvgIcon>
  );
};

export default Shield;
