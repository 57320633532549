import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Wardrobe = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19 19" fill="none">
      <g clipPath="url(#clip0_6800_50497)">
        <path
          d="M8.89764 1.24023C8.73206 1.24023 8.59766 1.37464 8.59766 1.54022V2.74025C8.59766 2.90583 8.73206 3.04023 8.89764 3.04023C9.06323 3.04023 9.19763 2.90583 9.19763 2.74025V1.54026C9.19767 1.37464 9.06327 1.24023 8.89764 1.24023Z"
          fill="currentColor"
        />
        <path
          d="M17.2961 1.24023C17.1305 1.24023 16.9961 1.37464 16.9961 1.54022V2.74025C16.9961 2.90583 17.1305 3.04023 17.2961 3.04023C17.4617 3.04023 17.5961 2.90583 17.5961 2.74025V1.54026C17.5961 1.37464 17.4617 1.24023 17.2961 1.24023Z"
          fill="currentColor"
        />
        <path
          d="M6.79609 0.0400391H0.796082C0.630496 0.0400391 0.496094 0.174441 0.496094 0.340027V17.74C0.496094 17.9056 0.630496 18.04 0.796082 18.04H6.79609C6.96168 18.04 7.09608 17.9056 7.09608 17.7401V0.340027C7.09608 0.174441 6.96168 0.0400391 6.79609 0.0400391ZM6.49611 17.44H1.09611V0.640051H6.49611V17.44Z"
          fill="currentColor"
        />
        <path
          d="M18.1961 12.6396H6.79608C6.6305 12.6396 6.49609 12.7741 6.49609 12.9396V17.7396C6.49609 17.9052 6.6305 18.0396 6.79608 18.0396H18.1961C18.3617 18.0396 18.4961 17.9052 18.4961 17.7396V12.9396C18.4961 12.7741 18.3617 12.6396 18.1961 12.6396ZM17.8961 17.4396H7.09607V13.2397H17.8961V17.4396Z"
          fill="currentColor"
        />
        <path
          d="M18.1961 0.0400391H6.79608C6.6305 0.0400391 6.49609 0.174441 6.49609 0.340027V1.54002C6.49609 1.7056 6.6305 1.84 6.79608 1.84H18.1961C18.3617 1.84 18.4961 1.7056 18.4961 1.54002V0.340027C18.4961 0.174441 18.3617 0.0400391 18.1961 0.0400391ZM17.8961 1.24003H7.09607V0.640051H17.8961V1.24003Z"
          fill="currentColor"
        />
        <path
          d="M5.29764 8.74023H4.39764C4.23206 8.74023 4.09766 8.87464 4.09766 9.04022C4.09766 9.20581 4.23206 9.34021 4.39764 9.34021H5.29764C5.46323 9.34021 5.59763 9.20581 5.59763 9.04022C5.59763 8.87464 5.46327 8.74023 5.29764 8.74023Z"
          fill="currentColor"
        />
        <path
          d="M4.39764 8.74023C4.23206 8.74023 4.09766 8.87464 4.09766 9.04022V9.94022C4.09766 10.1058 4.23206 10.2402 4.39764 10.2402C4.56323 10.2402 4.69763 10.1058 4.69763 9.94022V9.04022C4.69767 8.87464 4.56327 8.74023 4.39764 8.74023Z"
          fill="currentColor"
        />
        <path
          d="M13.3969 14.7402H11.5969C11.4313 14.7402 11.2969 14.8746 11.2969 15.0402C11.2969 15.2058 11.4313 15.3402 11.5969 15.3402H13.3969C13.5624 15.3402 13.6969 15.2058 13.6969 15.0402C13.6969 14.8746 13.5625 14.7402 13.3969 14.7402Z"
          fill="currentColor"
        />
        <path
          d="M12.4953 14.7402C12.3297 14.7402 12.1953 14.8746 12.1953 15.0402V15.6402C12.1953 15.8058 12.3297 15.9402 12.4953 15.9402C12.6609 15.9402 12.7953 15.8058 12.7953 15.6402V15.0402C12.7953 14.8746 12.6609 14.7402 12.4953 14.7402Z"
          fill="currentColor"
        />
        <path
          d="M10.6945 1.24023C10.5289 1.24023 10.3945 1.37464 10.3945 1.54022V3.34023C10.3945 3.50581 10.5289 3.64021 10.6945 3.64021C10.8601 3.64021 10.9945 3.50581 10.9945 3.34023V1.54022C10.9945 1.37464 10.8601 1.24023 10.6945 1.24023Z"
          fill="currentColor"
        />
        <path
          d="M10.6961 4.24023C9.08119 4.24023 7.99609 4.84292 7.99609 5.74024V6.34022C7.99609 6.50581 8.1305 6.64021 8.29608 6.64021H8.89609V9.3402C8.89609 9.50579 9.0305 9.64019 9.19608 9.64019H12.1961C12.3617 9.64019 12.4961 9.50579 12.4961 9.3402V6.64021H13.0961C13.2617 6.64021 13.3961 6.50581 13.3961 6.34022V5.74021C13.3961 4.84292 12.311 4.24023 10.6961 4.24023ZM12.7961 6.04023H12.1961C12.0305 6.04023 11.8961 6.17463 11.8961 6.34022V9.04022H9.49611V6.34022C9.49611 6.17463 9.3617 6.04023 9.19612 6.04023H8.59611V5.74024C8.59611 5.30406 9.332 4.84025 10.6961 4.84025C12.0602 4.84025 12.7961 5.30406 12.7961 5.74024V6.04023H12.7961Z"
          fill="currentColor"
        />
        <path
          d="M12.7953 4.24023C12.3954 4.24023 12.0204 4.27803 11.6811 4.35242C11.5434 4.38272 11.4453 4.5045 11.4453 4.64551C11.4453 4.78653 11.5434 4.90831 11.6811 4.93861C12.3579 5.0868 12.7953 5.40151 12.7953 5.74021V6.0402H12.1953C12.0297 6.0402 11.8953 6.1746 11.8953 6.34018V9.34017C11.8953 9.50575 12.0297 9.64016 12.1953 9.64016H14.2953C14.4609 9.64016 14.5953 9.50575 14.5953 9.34017V6.64017H15.1953C15.3609 6.64017 15.4953 6.50577 15.4953 6.34018V5.74017C15.4953 4.84292 14.4102 4.24023 12.7953 4.24023ZM14.8953 6.04023H14.2953C14.1297 6.04023 13.9953 6.17463 13.9953 6.34022V9.04022H12.4953V6.64021H13.0953C13.2609 6.64021 13.3953 6.50581 13.3953 6.34022V5.74021C13.3953 5.39522 13.2288 5.08711 12.9249 4.84172C14.2059 4.86992 14.8953 5.31781 14.8953 5.74021V6.04023Z"
          fill="currentColor"
        />
        <path
          d="M14.8969 4.24023C14.497 4.24023 14.122 4.27803 13.7827 4.35242C13.645 4.38272 13.5469 4.5045 13.5469 4.64551C13.5469 4.78653 13.645 4.90831 13.7827 4.93861C14.4595 5.0868 14.8969 5.40151 14.8969 5.74021V6.0402H14.2969C14.1313 6.0402 13.9969 6.1746 13.9969 6.34018V9.34017C13.9969 9.50575 14.1313 9.64016 14.2969 9.64016H16.3969C16.5624 9.64016 16.6968 9.50575 16.6968 9.34017V6.64017H17.2969C17.4624 6.64017 17.5968 6.50577 17.5968 6.34018V5.74017C17.5969 4.84292 16.5118 4.24023 14.8969 4.24023ZM16.9969 6.04023H16.3969C16.2313 6.04023 16.0969 6.17463 16.0969 6.34022V9.04022H14.5969V6.64021H15.1969C15.3624 6.64021 15.4969 6.50581 15.4969 6.34022V5.74021C15.4969 5.39522 15.3304 5.08711 15.0265 4.84172C16.3074 4.86992 16.9969 5.31781 16.9969 5.74021V6.04023V6.04023Z"
          fill="currentColor"
        />
        <path
          d="M10.6945 3.04004C10.5289 3.04004 10.3945 3.17444 10.3945 3.34003C10.3945 3.50561 10.5289 3.64002 10.6945 3.64002C10.8598 3.64002 10.9945 3.7747 10.9945 3.94C10.9945 4.10531 10.8598 4.23999 10.6945 4.23999C10.5289 4.23999 10.3945 4.37439 10.3945 4.53998C10.3945 4.70557 10.5289 4.83997 10.6945 4.83997C11.1907 4.83997 11.5945 4.43616 11.5945 3.93997C11.5945 3.44377 11.1907 3.04004 10.6945 3.04004Z"
          fill="currentColor"
        />
        <path
          d="M13.0969 1.24023C12.9313 1.24023 12.7969 1.37464 12.7969 1.54022V3.34023C12.7969 3.50581 12.9313 3.64021 13.0969 3.64021C13.2624 3.64021 13.3969 3.50581 13.3969 3.34023V1.54022C13.3969 1.37464 13.2624 1.24023 13.0969 1.24023Z"
          fill="currentColor"
        />
        <path
          d="M13.0969 3.04004C12.9313 3.04004 12.7969 3.17444 12.7969 3.34003C12.7969 3.50561 12.9313 3.64002 13.0969 3.64002C13.2622 3.64002 13.3969 3.7747 13.3969 3.94C13.3969 4.10531 13.2622 4.23999 13.0969 4.23999C12.9313 4.23999 12.7969 4.37439 12.7969 4.53998C12.7969 4.70557 12.9313 4.83997 13.0969 4.83997C13.5931 4.83997 13.9969 4.43616 13.9969 3.93997C13.9969 3.44377 13.5931 3.04004 13.0969 3.04004Z"
          fill="currentColor"
        />
        <path
          d="M15.4953 1.24023C15.3297 1.24023 15.1953 1.37464 15.1953 1.54022V3.34023C15.1953 3.50581 15.3297 3.64021 15.4953 3.64021C15.6609 3.64021 15.7953 3.50581 15.7953 3.34023V1.54022C15.7953 1.37464 15.6609 1.24023 15.4953 1.24023Z"
          fill="currentColor"
        />
        <path
          d="M15.4953 3.04004C15.3297 3.04004 15.1953 3.17444 15.1953 3.34003C15.1953 3.50561 15.3297 3.64002 15.4953 3.64002C15.6606 3.64002 15.7953 3.7747 15.7953 3.94C15.7953 4.10531 15.6606 4.23999 15.4953 4.23999C15.3297 4.23999 15.1953 4.37439 15.1953 4.53998C15.1953 4.70557 15.3297 4.83997 15.4953 4.83997C15.9915 4.83997 16.3953 4.43616 16.3953 3.93997C16.3953 3.44377 15.9915 3.04004 15.4953 3.04004Z"
          fill="currentColor"
        />
        <path
          d="M17.5961 11.4404H6.79608C6.6305 11.4404 6.49609 11.5748 6.49609 11.7404V12.9404C6.49609 13.106 6.6305 13.2404 6.79608 13.2404H17.5961C17.7617 13.2404 17.8961 13.106 17.8961 12.9404V11.7404C17.8961 11.5748 17.7617 11.4404 17.5961 11.4404ZM17.2961 12.6404H7.09607V12.0404H17.2961V12.6404H17.2961Z"
          fill="currentColor"
        />
        <path
          d="M16.9953 13.8398H7.9953C7.82971 13.8398 7.69531 13.9742 7.69531 14.1398V16.5398C7.69531 16.7054 7.82971 16.8398 7.9953 16.8398H16.9953C17.1609 16.8398 17.2953 16.7054 17.2953 16.5398V14.1398C17.2953 13.9742 17.1609 13.8398 16.9953 13.8398ZM16.6953 16.2399H8.29532V14.4399H16.6953V16.2399Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6800_50497">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.496094 0.0400391)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Wardrobe;
