import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";
import React from "react";

const Iconz = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 30 30"
      style={{
        width: 40,
        height: 40,
      }}
    >
      <path
        d="M16.0366 15.5644V13.7969C16.0366 13.5381 15.8266 13.3281 15.5679 13.3281C15.3091 13.3281 15.0991 13.5381 15.0991 13.7969V15.7419C15.0341 15.995 15.9098 16.7888 16.1379 17.0969C16.5448 17.555 17.2473 16.9369 16.8429 16.4756L16.0366 15.5644Z"
        fill="black"
      />
      <path
        d="M12 4.88878V0.939403H15.3187C16.0237 0.937528 16.5975 1.5069 16.6 2.2119V9.57065C16.5981 10.1807 17.5375 10.185 17.5375 9.57065V2.2119C17.5337 0.990028 16.5419 0.00127844 15.32 0.00190344C11.4906 2.84403e-05 6.04062 -0.00122156 2.21188 0.00190344C0.99125 0.00252844 0.0018753 0.991279 2.95952e-07 2.2119V15.3213C-0.000624704 16.5438 0.98875 17.5363 2.21188 17.5394H9.56937C10.1831 17.5319 10.1831 16.6075 9.56937 16.6019H2.21188C1.50688 16.5994 0.9375 16.0263 0.93875 15.3213V2.2119C0.94 1.5094 1.50938 0.940028 2.21188 0.939403H5.55937V4.88315C5.55937 5.54878 6.09937 6.08878 6.765 6.08878C7.22687 6.15253 8.25812 5.33628 8.64375 5.13878C8.71125 5.10253 8.79125 5.0969 8.86375 5.12253L10.2394 5.9594C11.0125 6.3844 12.0194 5.77128 12 4.88878ZM11.0637 4.88878C11.07 5.07753 10.8537 5.21815 10.6844 5.13253L9.3225 4.30565C8.46687 3.83003 7.59437 4.75815 6.89 5.12065C6.7175 5.21628 6.49312 5.0794 6.49687 4.88315V0.939403H11.0637V4.88878Z"
        fill="black"
      />
      <path
        d="M15.5691 11.4619C13.3016 11.4613 11.4635 13.2994 11.4629 15.5669C11.6498 21.0019 19.486 21.0013 19.6741 15.5682C19.6716 13.3013 17.8354 11.4644 15.5691 11.4619ZM15.5691 18.7375V18.735C13.8191 18.7357 12.401 17.3175 12.4004 15.5675C12.5291 11.3788 18.6085 11.38 18.7366 15.5675C18.736 17.3169 17.3185 18.735 15.5691 18.7375Z"
        fill="black"
      />
      <path
        d="M6.33711 13.0078H2.91273C2.30086 13.0103 2.29898 13.9428 2.91273 13.9453H6.33711C6.94898 13.9428 6.95086 13.0103 6.33711 13.0078Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default Iconz;
