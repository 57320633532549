import { SvgIcon, SvgIconProps } from "@mui/material";

const BonusT = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 640 512">
      <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L537.6 196.9C524.3 193.7 510.3 192 496 192C427.6 192 368.3 231 339.2 288H170.7L179.9 336H322.9C320.1 346.4 319.1 357.1 319.1 368C319.1 373.4 320.2 378.7 320.7 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.26 0 24C0 10.75 10.75 .0003 24 .0003L96 0zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM352 368C352 288.5 416.5 224 496 224C575.5 224 640 288.5 640 368C640 447.5 575.5 512 496 512C416.5 512 352 447.5 352 368zM555.3 331.3C561.6 325.1 561.6 314.9 555.3 308.7C549.1 302.4 538.9 302.4 532.7 308.7L496 345.4L459.3 308.7C453.1 302.4 442.9 302.4 436.7 308.7C430.4 314.9 430.4 325.1 436.7 331.3L473.4 368L436.7 404.7C430.4 410.9 430.4 421.1 436.7 427.3C442.9 433.6 453.1 433.6 459.3 427.3L496 390.6L532.7 427.3C538.9 433.6 549.1 433.6 555.3 427.3C561.6 421.1 561.6 410.9 555.3 404.7L518.6 368L555.3 331.3z" /><g mask="url(#mask0_10753_54574)">
<path d="M6.27344 20.5471C6.27344 19.7446 6.92397 19.0941 7.72643 19.0941C8.52887 19.0941 9.17943 19.7446 9.17943 20.5471C9.17943 21.3496 8.52887 22.0001 7.72643 22.0001C6.92397 22.0001 6.27344 21.3496 6.27344 20.5471Z" fill="black"/>
<path d="M14.8198 20.5471C14.8198 19.7446 15.4704 19.0941 16.2728 19.0941C17.0753 19.0941 17.7258 19.7446 17.7258 20.5471C17.7258 21.3496 17.0753 22.0001 16.2728 22.0001C15.4704 22.0001 14.8198 21.3496 14.8198 20.5471Z" fill="black"/>
<path d="M18.4146 2.17539C18.5268 2.06319 18.679 2.00015 18.8377 2.00015C18.9964 2.00015 19.1485 2.06319 19.2607 2.17539C19.3729 2.28759 19.436 2.43977 19.436 2.59844V4.56426H21.4018C21.5605 4.56426 21.7127 4.62729 21.8249 4.73949C21.937 4.85169 22.0001 5.00388 22.0001 5.16255C22.0001 5.32123 21.937 5.47341 21.8249 5.58561C21.7127 5.69781 21.5605 5.76084 21.4018 5.76084H19.436V7.72666C19.436 7.88533 19.3729 8.03751 19.2607 8.14971C19.1485 8.26194 18.9964 8.32494 18.8377 8.32494C18.679 8.32494 18.5269 8.26194 18.4146 8.14971C18.3024 8.03751 18.2394 7.88533 18.2394 7.72666V5.76084H16.2735C16.1149 5.76084 15.9627 5.69781 15.8505 5.58561C15.7383 5.47341 15.6753 5.32123 15.6753 5.16255C15.6753 5.00388 15.7383 4.85169 15.8505 4.73949C15.9627 4.62729 16.1149 4.56426 16.2735 4.56426H18.2394V2.59844C18.2394 2.43977 18.3024 2.28759 18.4146 2.17539Z" fill="black"/>
<path d="M2.17475 2.17541C2.28695 2.06321 2.43913 2.00018 2.5978 2.00018H2.78755C3.35249 2.00044 3.89771 2.20793 4.31989 2.58333C4.7421 2.95875 5.01195 3.47632 5.07824 4.03739L5.14111 4.56428H12.8542C13.0129 4.56428 13.1651 4.62732 13.2773 4.73952C13.3895 4.85172 13.4525 5.0039 13.4525 5.16257C13.4525 5.32125 13.3895 5.47342 13.2773 5.58563C13.1651 5.69783 13.0129 5.76087 12.8542 5.76087H5.28215L6.14583 13.1113H16.8346C17.4958 13.1112 18.136 12.8788 18.6433 12.4546C19.1506 12.0306 19.4927 11.4417 19.61 10.791L19.6102 10.7896C19.6237 10.7123 19.6525 10.6384 19.6947 10.5723C19.737 10.5061 19.792 10.449 19.8565 10.4043C19.921 10.3595 19.9937 10.328 20.0705 10.3116C20.1472 10.2952 20.2265 10.2942 20.3037 10.3086L20.3054 10.309C20.3827 10.3229 20.4566 10.3519 20.5228 10.3944C20.5889 10.4368 20.6461 10.492 20.6909 10.5565C20.7357 10.6211 20.7674 10.6938 20.7841 10.7706C20.8008 10.8475 20.8022 10.9268 20.7882 11.0042C20.621 11.931 20.1334 12.7695 19.4108 13.3733C18.6881 13.9772 17.7763 14.308 16.8346 14.3079H6.27622L6.39708 14.6498C6.59154 15.1998 6.95175 15.676 7.42809 16.0127C7.90442 16.3495 8.4735 16.5302 9.05681 16.5301H17.9824C18.1411 16.5301 18.2933 16.5931 18.4055 16.7053C18.5177 16.8175 18.5807 16.9697 18.5807 17.1284C18.5807 17.2871 18.5177 17.4393 18.4055 17.5515C18.2933 17.6636 18.1411 17.7267 17.9824 17.7267H9.05675C8.07247 17.7267 7.12242 17.3653 6.38694 16.7111C5.65147 16.057 5.18176 15.1555 5.06699 14.178L3.89092 4.17787C3.85907 3.90759 3.72915 3.6584 3.52577 3.47755C3.3224 3.29671 3.0597 3.19679 2.78755 3.19676H2.5978C2.43913 3.19676 2.28695 3.13372 2.17475 3.02152C2.06255 2.90932 1.99951 2.75714 1.99951 2.59847C1.99951 2.43979 2.06255 2.28761 2.17475 2.17541Z" fill="black"/>
</g>
    </SvgIcon>
  );
};

export default BonusT;
