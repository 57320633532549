import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const CarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 480 480">

   <g>
    <path  d="M160.05 254.81l0 227.09 309.29 0 0.07 -212.18c-0.32,-30.25 -11.09,-30.97 -39.81,-30.84l-247.39 0.27c-15.91,1.59 -15.26,6.44 -22.15,15.67z"/>
    <path  d="M551.52 545.3c35.34,-8.73 43.83,42.4 13.53,50.13 -34.91,8.91 -47.68,-41.7 -13.53,-50.13zm-263.82 -0.46c35.27,-6.75 42.28,45.07 10.47,50.99 -35.37,6.58 -44.43,-44.49 -10.47,-50.99zm247.65 -195.51c62.15,0 63.08,-6.2 81.78,26.78 7.06,12.44 16.47,26.73 22.02,39.24l-103.53 0.28 -0.27 -66.3zm-375.3 154.66l0 50.35c7.94,10.63 6.56,15.07 26.4,15.91 12.82,0.54 26.99,0 39.97,0 7.28,88.27 125.13,88.24 132.47,0l132.66 0c7.23,88.25 125.11,88.25 132.34,0 59.55,0 51.95,-8.52 66.39,-34.76l0 -118.75c-5.42,-7.67 -9.47,-17.66 -14.88,-26 -49.04,-75.6 -35.52,-85.61 -106.63,-85.58 -25.77,0.01 -51.54,0 -77.32,0l0 198.83 -331.4 0z"/>
    <path  d="M410.31 773.81c115.14,0 208.47,-57.38 208.47,-128.16 0,-70.78 -93.33,-128.16 -208.47,-128.16 -115.14,0 -208.47,57.38 -208.47,128.16 0,70.78 93.33,128.16 208.47,128.16z"/>
    <path  d="M410.31 46.81c200.76,0 363.5,162.75 363.5,363.5 0,200.76 -162.75,363.5 -363.5,363.5 -200.76,0 -363.5,-162.75 -363.5,-363.5 0,-200.76 162.75,-363.5 363.5,-363.5z"/>

  </g>

    </SvgIcon>
  );
};

export default CarIcon;
