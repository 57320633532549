import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const CartThick = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 14">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.4879 2.00572C3.51441 2.00193 3.54129 2 3.56838 2H3.74864C4.22582 2.00025 4.68819 2.15612 5.06675 2.44136C5.11405 2.47699 5.16004 2.51465 5.20458 2.55428C5.6057 2.91108 5.86201 3.4031 5.92482 3.93626L5.98461 4.43591H19.329C19.65 4.43552 19.9672 4.50581 20.258 4.6418C20.5488 4.77779 20.8064 4.97654 21.0119 5.22315C21.2178 5.46913 21.367 5.75738 21.4491 6.06748C21.5274 6.36325 21.5427 6.67222 21.4942 6.97401C21.4919 6.98862 21.4894 7.00321 21.4867 7.01779L20.8494 10.5531C20.6906 11.4337 20.2275 12.2304 19.541 12.8043C18.8545 13.3781 17.9881 13.6924 17.0934 13.6924H7.07019L7.18502 14.0172C7.36929 14.5384 7.71027 14.9898 8.1612 15.3097C8.61212 15.6294 9.15094 15.8019 9.70373 15.8035H18.1838C18.3346 15.8035 18.4792 15.8634 18.5857 15.97C18.6924 16.0765 18.7522 16.2211 18.7522 16.3719C18.7522 16.5226 18.6924 16.6672 18.5857 16.7738C18.4792 16.8804 18.3346 16.9403 18.1838 16.9403H9.70444C8.76934 16.9403 7.86678 16.597 7.16808 15.9755C6.46937 15.3541 6.02315 14.4976 5.91412 13.5689L4.79684 4.06882C4.76659 3.81205 4.64316 3.57532 4.44995 3.40352C4.25675 3.23171 4.00718 3.13679 3.74864 3.13676H3.56838C3.41764 3.13676 3.27307 3.07687 3.16647 2.97028C3.05988 2.86369 3 2.71912 3 2.56838C3 2.41764 3.05988 2.27307 3.16647 2.16647C3.25391 2.07904 3.36691 2.02303 3.4879 2.00572ZM19.7764 5.67005C19.6359 5.6051 19.4828 5.57186 19.328 5.57267H6.11849L6.93986 12.5556H17.093C17.7219 12.5565 18.3311 12.3363 18.814 11.9333C19.2969 11.5302 19.6225 10.9703 19.7341 10.3513L20.3723 6.81613C20.3811 6.76735 20.3865 6.71815 20.3884 6.66888C20.3924 6.56401 20.3808 6.45884 20.3537 6.35689C20.3138 6.20704 20.2414 6.0678 20.1417 5.94909C20.0419 5.83038 19.9172 5.73511 19.7764 5.67005Z"
          fill="black"
        />
        <path
          d="M19.328 5.57267C19.4828 5.57186 19.6359 5.6051 19.7764 5.67005C19.9172 5.73511 20.0419 5.83038 20.1417 5.94909C20.2414 6.0678 20.3138 6.20704 20.3537 6.35689C20.3808 6.45884 20.3924 6.56401 20.3884 6.66888C20.3865 6.71815 20.3811 6.76735 20.3723 6.81613L19.7341 10.3513C19.6225 10.9703 19.2969 11.5302 18.814 11.9333C18.3311 12.3363 17.7219 12.5565 17.093 12.5556H6.93986L6.11849 5.57267H19.328ZM19.328 5.57267L19.3287 5.81616M3.4879 2.00572C3.51441 2.00193 3.54129 2 3.56838 2H3.74864C4.22582 2.00025 4.68819 2.15612 5.06675 2.44136C5.11405 2.47699 5.16004 2.51465 5.20458 2.55428C5.6057 2.91108 5.86201 3.4031 5.92482 3.93626L5.98461 4.43591H19.329C19.65 4.43552 19.9672 4.50581 20.258 4.6418C20.5488 4.77779 20.8064 4.97654 21.0119 5.22315C21.2178 5.46913 21.367 5.75738 21.4491 6.06748C21.5274 6.36325 21.5427 6.67222 21.4942 6.97401C21.4919 6.98862 21.4894 7.00321 21.4867 7.01779L20.8494 10.5531C20.6906 11.4337 20.2275 12.2304 19.541 12.8043C18.8545 13.3781 17.9881 13.6924 17.0934 13.6924H7.07019L7.18502 14.0172C7.36929 14.5384 7.71027 14.9898 8.1612 15.3097C8.61212 15.6294 9.15094 15.8019 9.70373 15.8035H18.1838C18.3346 15.8035 18.4792 15.8634 18.5857 15.97C18.6924 16.0765 18.7522 16.2211 18.7522 16.3719C18.7522 16.5226 18.6924 16.6672 18.5857 16.7738C18.4792 16.8804 18.3346 16.9403 18.1838 16.9403H9.70444C8.76934 16.9403 7.86678 16.597 7.16808 15.9755C6.46937 15.3541 6.02315 14.4976 5.91412 13.5689L4.79684 4.06882C4.76659 3.81205 4.64316 3.57532 4.44995 3.40352C4.25675 3.23171 4.00718 3.13679 3.74864 3.13676H3.56838C3.41764 3.13676 3.27307 3.07687 3.16647 2.97028C3.05988 2.86369 3 2.71912 3 2.56838C3 2.41764 3.05988 2.27307 3.16647 2.16647C3.25391 2.07904 3.36691 2.02303 3.4879 2.00572Z"
          stroke="black"
        />
        <path
          d="M8.44041 18.75C7.53999 18.75 6.81006 19.4799 6.81006 20.3804C6.81006 21.2807 7.53998 22.0107 8.44041 22.0107C9.34084 22.0107 10.0707 21.2807 10.0707 20.3804C10.0707 19.4799 9.34083 18.75 8.44041 18.75Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
        <path
          d="M16.56 18.75C15.6596 18.75 14.9297 19.4799 14.9297 20.3804C14.9297 21.2807 15.6596 22.0107 16.56 22.0107C17.4604 22.0107 18.1903 21.2807 18.1903 20.3804C18.1903 19.4799 17.4604 18.75 16.56 18.75Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
      </svg>
    </SvgIcon>
  );
};

export default CartThick;
