import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Thermometer = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" fill="none">
      <path
        d="M9.12998 10.5126C9.08451 10.5127 9.03949 10.5038 8.99748 10.4864C8.95548 10.469 8.91732 10.4434 8.88521 10.4112L7.58966 9.11569C7.52475 9.05078 7.48828 8.96273 7.48828 8.87093C7.48828 8.77912 7.52475 8.69108 7.58966 8.62616L11.7072 4.50863C11.7393 4.47648 11.7775 4.45098 11.8195 4.43358C11.8615 4.41618 11.9065 4.40723 11.952 4.40723C11.9974 4.40723 12.0425 4.41618 12.0845 4.43358C12.1265 4.45098 12.1646 4.47648 12.1968 4.50863L13.4923 5.80414C13.5244 5.83629 13.5499 5.87445 13.5673 5.91645C13.5847 5.95845 13.5937 6.00346 13.5937 6.04893C13.5937 6.09439 13.5847 6.1394 13.5673 6.1814C13.5499 6.2234 13.5244 6.26156 13.4923 6.29371L9.37475 10.4112C9.34263 10.4434 9.30448 10.469 9.26248 10.4864C9.22047 10.5038 9.17544 10.5127 9.12998 10.5126ZM8.324 8.87093L9.12998 9.67691L12.7579 6.04894L11.952 5.24296L8.324 8.87093Z"
        fill="currentColor"
      />
      <path
        d="M16.1669 1.8334C15.7015 1.36913 15.0711 1.1084 14.4137 1.1084C13.7564 1.1084 13.1259 1.36913 12.6606 1.8334L6.23774 8.25618C5.78035 8.71339 5.45915 9.28881 5.31005 9.9181L5.09616 10.8205C4.97666 11.3249 4.71922 11.7861 4.35262 12.1526L1.4181 15.087C1.22109 15.2855 1.11079 15.5541 1.11133 15.8337C1.11187 16.1134 1.22322 16.3815 1.42099 16.5793C1.61876 16.7771 1.88684 16.8884 2.16654 16.8889C2.44623 16.8895 2.71474 16.7792 2.91328 16.5822L5.84773 13.6477C6.21424 13.281 6.67553 13.0236 7.18 12.9041L8.08239 12.6901C8.71167 12.541 9.28708 12.2198 9.74428 11.7624L16.1669 5.33973C16.6312 4.8744 16.8919 4.2439 16.8919 3.58657C16.8919 2.92923 16.6312 2.29874 16.1669 1.8334ZM2.42385 16.0926C2.3554 16.1611 2.26256 16.1995 2.16576 16.1995C2.06895 16.1995 1.97611 16.1611 1.90766 16.0926C1.83921 16.0242 1.80076 15.9313 1.80076 15.8345C1.80076 15.7377 1.83921 15.6449 1.90766 15.5764L2.67266 14.8114L3.18871 15.3275L2.42385 16.0926ZM9.25454 11.273C8.88807 11.6396 8.42686 11.897 7.92247 12.0166L7.02008 12.2305C6.39079 12.3796 5.81538 12.7008 5.3582 13.1582L3.67817 14.8382L3.16213 14.3221L4.84211 12.6421C5.29951 12.1849 5.62071 11.6095 5.76981 10.9802L5.98376 10.0778C6.10327 9.57342 6.36071 9.11221 6.7273 8.74575L12.6471 2.8259L15.1744 5.35316L9.25454 11.273ZM15.6774 4.8502L15.6639 4.86363L13.1367 2.33637L13.1501 2.32294C13.4855 1.98906 13.9396 1.80186 14.4129 1.8024C14.8861 1.80293 15.3398 1.99117 15.6745 2.32581C16.0091 2.66045 16.1974 3.11416 16.1979 3.58741C16.1984 4.06066 16.0112 4.5148 15.6774 4.8502Z"
        fill="currentColor"
      />
      <path
        d="M13.3715 4.97551C13.5627 4.97551 13.7177 4.82053 13.7177 4.62936C13.7177 4.43818 13.5627 4.2832 13.3715 4.2832C13.1804 4.2832 13.0254 4.43818 13.0254 4.62936C13.0254 4.82053 13.1804 4.97551 13.3715 4.97551Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Thermometer;
